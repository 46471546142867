import Cookies from 'js-cookie';

import { APP_NAME } from '../../scripts/constants';
import { NodeEnvironment } from '../../scripts/enums/NodeEnvironment';
import { SentryEnvironment } from '../../scripts/enums/SentryEnvironment';

export interface AppConfig {
  /** The name of the application. */
  appName: typeof APP_NAME;
  /** The long name of the application. */
  appLongName: string;
  /** The name of the release which consists of the long name and the release version. */
  releaseName: string;
  /** The LAMA Auth GraphQL API for authenticated requests. */
  lamaAuthGraphqlApi: string;
  /** The LAMA Anonymous GraphQL API for unauthenticated requests. */
  lamaAnonymousGraphqlApi: string;
  /** The LAMA REST API. */
  lamaRestApi: string;
  /** Whether the LAMA REST API is a local endpoint. */
  isLocalLamaEndpoint: boolean;
  /** Whether the LAMA REST API is a development endpoint. */
  isDevLamaEndpoint: boolean;
  /** Whether the LAMA REST API is a stage endpoint. */
  isStageLamaEndpoint: boolean;
  /** Whether the LAMA REST API is a UAT (user acceptance testing)( preview endpoint. */
  isUatPreviewLamaEndpoint: boolean;
  /** Whether the LAMA REST API is a production endpoint. */
  isProdLamaEndpoint: boolean;
  /** The node environment. */
  nodeEnvironment: NodeEnvironment;
  /** The environment prefix to redirect users to the correct Link environment. */
  environmentPrefix: string;

  /**
   * Get the LKP preview URL if applicable.
   * This is not a `get` because it does a document query which we don't want to
   * do until the tests are running properly in the JSDOM environment.
   **/
  getLkpPreviewUrl(): string | null;

  /** Whether the build is a production build. */
  isProdBuild: boolean;
  /** The Sentry DSN. */
  sentryDsn?: string;
  /** The Sentry environment. */
  sentryEnvironment: SentryEnvironment;
  /** Whether the Sentry environment is local. */
  isLocalSentryEnvironment: boolean;
  /** Whether the Sentry environment is development. */
  isDevSentryEnvironment: boolean;
  /** Whether the Sentry environment is user acceptance testing. */
  isUatSentryEnvironment: boolean;
  /** Whether the Sentry environment is stage. */
  isStageSentryEnvironment: boolean;
  /** Whether the Sentry environment is production. */
  isProdSentryEnvironment: boolean;
  /** The Matomo site ID. */
  matomoSiteId: number;
  /**
   * Whether the application is running in a test environment.
   * This happens when running E2E tests.
   **/
  isTestEnvironment: boolean;
  /**
   * The last used application by the user.
   * This is used to redirect the user to the last used application after login.
   */
  lastUsedApp: string;
  // Define other configuration options here.
}

/**
 * The application configuration using environment variables.
 * The type casts exist to make "lint:ts:e2e" due to its limited tsconfig.json
 * configuration: https://playwright.dev/docs/test-typescript#tsconfigjson
 */
export const appConfig: Readonly<AppConfig> = {
  appName: APP_NAME,
  appLongName: `lama-user-${APP_NAME}-ui`,
  releaseName: process.env.REACT_APP_RELEASE_NAME,
  lamaAuthGraphqlApi: `${process.env.REACT_APP_LAMA_GRAPHQL_API}/graphql`,
  lamaAnonymousGraphqlApi: `${process.env.REACT_APP_LAMA_GRAPHQL_API}/auth/graphql`,
  lamaRestApi: process.env.REACT_APP_LAMA_GRAPHQL_API,
  get isLocalLamaEndpoint() {
    return this.lamaRestApi.includes('localhost');
  },
  get isDevLamaEndpoint() {
    return this.lamaRestApi.includes('.dev.');
  },
  get isStageLamaEndpoint() {
    return this.lamaRestApi.includes('.stage.');
  },
  get isUatPreviewLamaEndpoint() {
    return this.lamaRestApi.includes('.preview.');
  },
  get isProdLamaEndpoint() {
    return !this.isLocalLamaEndpoint && !this.isDevLamaEndpoint && !this.isStageLamaEndpoint;
  },
  nodeEnvironment: process.env.NODE_ENV as NodeEnvironment,
  get environmentPrefix() {
    if (this.isDevLamaEndpoint) {
      return 'dev.';
    }

    if (this.isStageLamaEndpoint) {
      return 'stage.';
    }

    if (this.isUatPreviewLamaEndpoint) {
      return 'preview.';
    }

    return '';
  },
  getLkpPreviewUrl() {
    // LKP previews take the form of https://shell-preview.stage.veeva.link/BRANCH-NAME/
    // https://github.com/veeva-oncology-link/ui-shell/blob/stage/.github/workflows/pull-request-preview.yml#L38

    const baseElement = document.querySelector('base');

    // A hacky way to determine if the app is running in a LKP preview.
    // Extra checks are added to ensure that the app is running in a LKP preview
    // because the base tag is also used in other environments.
    if (
      // LKP previews are only available in the stage environment.
      // https://github.com/veeva-oncology-link/ui-shell/blob/stage/.github/workflows/pull-request-preview.yml#L38
      this.isStageLamaEndpoint &&
      // LKP deploys its previews to the shell-preview subdomain.
      // https://github.com/veeva-oncology-link/ui-shell/blob/stage/.github/workflows/pull-request-preview.yml#L70
      window.location.hostname.includes('shell-preview') &&
      // The `<base href="..." />` tag is set here on every environment https://github.com/veeva-oncology-link/ui-shell/blob/stage/webpack.config.js#L122
      baseElement
    ) {
      return baseElement.getAttribute('href');
    }

    return null;
  },
  get isProdBuild() {
    return this.nodeEnvironment === NodeEnvironment.Production;
  },
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT as SentryEnvironment,
  get isLocalSentryEnvironment() {
    return this.sentryEnvironment === SentryEnvironment.Local;
  },
  get isDevSentryEnvironment() {
    return this.sentryEnvironment === SentryEnvironment.Dev;
  },
  get isStageSentryEnvironment() {
    return this.sentryEnvironment === SentryEnvironment.Stage;
  },
  get isUatSentryEnvironment() {
    return this.sentryEnvironment === SentryEnvironment.Uat;
  },
  get isProdSentryEnvironment() {
    return this.sentryEnvironment === SentryEnvironment.Prod;
  },
  get lastUsedApp() {
    const storedCookieURL = Cookies.get('veeva.link.defaultApp') || '';
    return storedCookieURL;
  },
  matomoSiteId: Number(process.env.REACT_APP_MATOMO_SITE_ID),
  isTestEnvironment: Boolean(process.env.TEST),
};
